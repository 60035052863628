<template>
  <div class="home-page">
    <div class="image">
      <header
        class="my-header d-flex align-items-center"
        style="margin-top: -6px; "
      >
        <div class="container">
          <div class="my-header-content row">
            <div
              class="my-header-left-content col-sm-12 col-md-6 col-lg-6 d-inline-block"
            >
              <h1
                style="font-size:35px;font-family:Montserrat-extrabold"
                class="mb-0"
              >
                "{{ $t("OnlineAdm") }}"
              </h1>
              <p
                class="text-end"
                style="font-size:20px;font-family:Montserrat-extrabold;color:#2A3B5D"
              >
                {{ $t("Ш.Мирзиёев") }}
              </p>
              <p style="font-size:16px" class="col-sm-12 col-md-8">
                {{ $t("Process") }}
              </p>

              <div>
                <button class="my-button my-bg-danger" @click="OpenSidebar">
                  {{ $t("enter") }}
                </button>
              </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
              <img src="/img/headerimg.png" alt="" />
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="w-100" style="background-color:#F7F9FF" id="news">
      <div class="container">
        <b-row class="py-3 align-items-center">
          <b-col sm="12" md="6">
            <span style="font-size: 24px;font-family:Montserrat-extrabold">{{
              $t("News")
            }}</span>
          </b-col>
          <b-col sm="12" md="6" class="text-end">
            <button
              class="my-button my-bg-primary"
              @click="$router.push({ name: 'News' })"
            >
              {{ $t("AllNews") }}
            </button>
          </b-col>
        </b-row>
        <b-row style="min-height:400px" class="pb-3">
          <b-col>
            <VueSlickCarousel v-bind="settings">
              <div
                class="w-100 p-2 h-100"
                style="height:400px !important"
                v-for="(item, index) in News"
                :key="index"
                @click="GetInfo(item)"
              >
                <div class="w-100 bg-danger h-100 position-relative">
                  <img
                    width="100%"
                    height="400px"
                    :src="
                      axios.defaults.baseURL +
                        `News/GetNewsImage/${item.imageId}`
                    "
                    alt=""
                  />
                  <div
                    class="w-100 position-absolute top-0 left-0 h-100 my-news-bg"
                  >
                    <div
                      class="d-flex align-items-end w-100 h-100 mb-5 text-white"
                    >
                      <div class="m-5 ">
                        <p class="news-text">
                          {{ item.title }}
                        </p>
                        <span> {{ item.date }} {{ $t("y") }} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <template #prevArrow>
                <b-icon-chevron-left
                  scale="1.6"
                  class="text-primary"
                ></b-icon-chevron-left>
              </template>
              <template #nextArrow>
                <b-icon-chevron-right
                  scale="1.6"
                  class="text-primary"
                ></b-icon-chevron-right>
              </template>
            </VueSlickCarousel>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="w-100 pb-3" id="ControlFunction">
      <div class="container">
        <b-row class="py-3 align-items-center">
          <b-col sm="12" md="6">
            <span style="font-size: 24px;font-family:Montserrat-extrabold">{{
              $t("ControlFunction")
            }}</span>
          </b-col>
          <b-col sm="12" md="6" class="text-end"> </b-col>
        </b-row>
        <b-row class="mt-4 " style="margin-left:-6px">
          <b-col sm="12" md="6">
            <v-select
              :placeholder="$t('organization')"
              class="my-select"
              :options="OrganizationList"
              :reduce="(item) => item.value"
              label="text"
              v-model="ControlFuncFilter.parentOrganizationId"
              @input="ControlFuncRefresh"
            ></v-select>
          </b-col>
          <b-col sm="12" md="6">
            <button class="my-button my-bg-primary" @click="ControlFuncRefresh">
              <b-spinner v-if="ControlFuncLoading" small></b-spinner>
              {{ $t("refresh") }}
            </button>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col
            sm="12"
            class="my-2"
            v-for="(item, index) in ControlFuncList"
            :key="index"
          >
            <b-row class="my-card-border mx-2">
              <b-col sm="4" style="padding-left:40px">
                <p class="pt-3 my-text-primary">{{ $t("forms") }} :</p>
                <p>{{ item.forms.join(",") }}</p>
              </b-col>
              <b-col sm="4">
                <p class="pt-3 my-text-primary">{{ $t("normativdoc") }} :</p>
                <p>{{ item.normativeLegalDoc }}</p>
              </b-col>
              <b-col sm="4">
                <p class="pt-3 my-text-primary">{{ $t("function") }} :</p>
                <p>{{ item.name }}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-3" v-if="ControlFuncList.length > 0">
          <b-col class="d-flex justify-content-end">
            <b-pagination
              v-model="ControlFuncFilter.page"
              :total-rows="ControlFuncFilter.totalRows"
              :per-page="ControlFuncFilter.pageSize"
              first-number
              last-number
              @input="ControlFuncRefresh"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <b-icon icon="chevron-double-left" size="18" />
              </template>
              <template #next-text>
                <b-icon icon="chevron-double-right" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
    <div
      class="w-100 pb-4"
      style="background-color:#F7F9FF"
      id="MandRequirement"
    >
      <div class="container">
        <b-row class="py-3 align-items-center">
          <b-col sm="12" md="6">
            <span style="font-size: 24px;font-family:Montserrat-extrabold">{{
              $t("MandRequirement")
            }}</span>
          </b-col>
          <b-col sm="12" md="6" class="text-end"> </b-col>
        </b-row>
        <b-row class="mt-3 " style="margin-left:-6px">
          <b-col sm="12" md="6">
            <v-select
              :placeholder="$t('organization')"
              class="my-select"
              :options="OrganizationList"
              :reduce="(item) => item.value"
              label="text"
              v-model="MandatoryRequirementFilter.parentOrganizationId"
              @input="MandatoryRequirementRefresh"
            ></v-select>
          </b-col>
          <b-col sm="12" md="6">
            <button
              class="my-button my-bg-primary"
              @click="MandatoryRequirementRefresh"
            >
              <b-spinner v-if="MandatoryRequirementLoading" small></b-spinner>
              {{ $t("refresh") }}
            </button>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col sm="12" class="my-2">
            <b-row class="my-card-border mx-2 pt-3 my-bg-primary-dark">
              <b-col sm="12" md="5" style="padding-left:40px">
                <p style="font-family:Montserrat-bold">
                  {{ $t("normativdoc") }}
                </p>
              </b-col>
              <b-col sm="12" md="6">
                <p style="font-family:Montserrat-bold">
                  {{ $t("MandRequirement1") }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            sm="12"
            class="my-2"
            v-for="(item, index) in MandatoryRequirement"
            :key="index"
          >
            <b-row
              class="my-card-border mx-2 pt-3 bg-white d-flex align-items-center"
            >
              <b-col sm="12" md="5" style="padding-left:40px">
                <p style="font-family:Montserrat-bold">
                  {{ item.normativeLegalDoc }}
                </p>
              </b-col>
              <b-col sm="12" md="6">
                <p style="font-family:Montserrat-bold">{{ item.name }}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-3" v-if="MandatoryRequirement.length > 0">
          <b-col class="d-flex justify-content-end">
            <b-pagination
              v-model="MandatoryRequirementFilter.page"
              :total-rows="MandatoryRequirementFilter.totalRows"
              :per-page="MandatoryRequirementFilter.pageSize"
              first-number
              last-number
              @input="MandatoryRequirementRefresh"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <b-icon icon="chevron-double-left" size="18" />
              </template>
              <template #next-text>
                <b-icon icon="chevron-double-right" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="w-100 pb-4" style="background-color:#fff" id="HealthRiskFactor">
      <div class="container">
        <b-row class="py-3 align-items-center">
          <b-col sm="12" md="6">
            <span style="font-size: 24px;font-family:Montserrat-extrabold">{{
              $t("HealthRiskFactor")
            }}</span>
          </b-col>
          <b-col sm="12" md="6" class="text-end"> </b-col>
        </b-row>
        <b-row class="mt-3 " style="margin-left:-6px">
          <b-col sm="12" md="6">
            <v-select
              :placeholder="$t('organization')"
              class="my-select"
              :options="OrganizationList"
              :reduce="(item) => item.value"
              label="text"
              v-model="HealthRiskFactorFilter.parentOrganizationId"
              @input="HealthRiskFactorRefresh"
            ></v-select>
          </b-col>
          <b-col sm="12" md="6">
            <button
              class="my-button my-bg-primary"
              @click="HealthRiskFactorRefresh"
            >
              <b-spinner v-if="HealthRiskFactorLoading" small></b-spinner>
              {{ $t("refresh") }}
            </button>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col sm="12" class="my-2">
            <b-row class="my-card-border mx-2 pt-3 my-bg-primary-dark">
              <b-col sm="12" md="5" style="padding-left:40px">
                <p style="font-family:Montserrat-bold">
                  {{ $t("name") }}
                </p>
              </b-col>
              <b-col sm="12" md="6">
                <p style="font-family:Montserrat-bold">
                  {{ $t("parentOrganization") }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            sm="12"
            class="my-2"
            v-for="(item, index) in HealthRiskFactor"
            :key="index"
          >
            <b-row
              class="my-card-border mx-2 pt-3 bg-white d-flex align-items-center"
            >
              <b-col sm="12" md="5" style="padding-left:40px">
                <p style="font-family:Montserrat-bold">
                  {{ item.name }}
                </p>
              </b-col>
              <b-col sm="12" md="6">
                <p style="font-family:Montserrat-bold">
                  {{ item.parentOrganization }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-3" v-if="HealthRiskFactor.length > 0">
          <b-col class="d-flex justify-content-end">
            <b-pagination
              v-model="HealthRiskFactorFilter.page"
              :total-rows="HealthRiskFactorFilter.totalRows"
              :per-page="HealthRiskFactorFilter.pageSize"
              first-number
              last-number
              @input="HealthRiskFactorRefresh"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <b-icon icon="chevron-double-left" size="18" />
              </template>
              <template #next-text>
                <b-icon icon="chevron-double-right" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="w-100 pb-4">
      <div class="container">
        <b-row class="py-5 align-items-center">
          <b-col sm="12" md="6">
            <span style="font-size: 24px;font-family:Montserrat-extrabold">{{
              $t("Рақамлар")
            }}</span>
          </b-col>
          <b-col sm="12" md="6" class="text-end"> </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6" lg="3">
            <b-row>
              <b-col sm="12" md="12" class="d-flex align-items-center">
                <div
                  style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px"
                  class="mr-3"
                >
                  <number
                    class="bold transition"
                    style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px;color:#2A3B5D"
                    :class="{ scaleBig: scaleClass }"
                    ref="number2"
                    :from="0"
                    animation-paused
                    :to="PageData.allRequestCount"
                    :duration="5"
                    easing="Power4.easeOut"
                    @complete="completed"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="6" lg="3">
            <b-row>
              <b-col sm="12" md="12" class="d-flex align-items-center">
                <div
                  style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px"
                  class="mr-3"
                >
                  <number
                    class="bold transition"
                    style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px;color:#2A3B5D"
                    :class="{ scaleBig: scaleClass }"
                    ref="number3"
                    :from="0"
                    animation-paused
                    :to="PageData.inspectionOrganiztionCount"
                    :duration="5"
                    easing="Power4.easeOut"
                    @complete="completed"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="3"
            v-for="(item, index) in PageData.otherElements"
            :key="index"
          >
            <b-row>
              <b-col sm="12" md="12" class="d-flex align-items-center">
                <div
                  style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px"
                  class="mr-3"
                >
                  <number
                    class="bold transition"
                    style="font-family:Montserrat-extrabold;font-size:50px;margin-right:20px;color:#2A3B5D"
                    :class="{ scaleBig: scaleClass }"
                    :ref="'number7' + index"
                    :from="0"
                    animation-paused
                    :to="item.value"
                    :duration="5"
                    easing="Power4.easeOut"
                    @complete="completed"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="8" lg="3">
            <b-row>
              <b-col sm="12" md="8" class="d-flex align-items-center">
                <div class="text-left">
                  {{ $t("agreedRequestCount") }}
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="8" lg="3">
            <b-row>
              <b-col sm="12" md="8" class="d-flex align-items-center">
                <div class="text-left">
                  {{ $t("allRequestCount") }}
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            sm="12"
            md="8"
            lg="3"
            v-for="(item, index) in PageData.otherElements"
            :key="index"
          >
            <b-row>
              <b-col sm="12" md="8" class="d-flex align-items-center">
                <div class="text-left">
                  <!-- {{ $t("test1") }} -->
                  {{ item.label }}
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <!-- Test-->
        </b-row>
        <b-row> </b-row>
      </div>
    </div>
    <div class="w-100" style="background-color:#F7F9FF" id="Videodarsliklar">
      <div class="container">
        <b-row class="pt-5 align-items-center">
          <b-col sm="12" md="6">
            <span style="font-size: 24px;font-family:Montserrat-extrabold">{{
              $t("Videodarsliklar")
            }}</span>
          </b-col>
          <b-col sm="12" md="6" class="text-end"> </b-col>
        </b-row>
        <b-row class="py-4">
          <b-col sm="12" md="6" lg="4">
            <b-row
              class="my-card-border m-2  pt-3 bg-white d-flex align-items-center"
              v-for="(item, index) in VideoCategoryList"
              @click="SetVideoCategory(item)"
              :style="{
                backgroundColor:
                  videocategory == item.value ? '#5965CE !important' : '',
                color: videocategory == item.value ? '#fff' : '',
              }"
              style="cursor:pointer"
              :key="index"
            >
              <b-col sm="12" style="padding-left:40px">
                <p style="font-family:Montserrat-bold">{{ item.text }}</p>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="6" lg="8">
            <b-row>
              <b-col v-if="videolesson.length == 1" sm="12">
                <LazyYoutube :maxWidth="true" :src="videolesson[0].uri" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                v-show="videolesson.length != 1 && videolesson.length != 0"
                v-for="(item, index) in videolesson"
                :key="index"
              >
                <LazyYoutube :maxWidth="true" :src="item.uri" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="w-100 text-white py-5" style="background-color:#1F2A51">
      <div class="container">
        <b-row>
          <b-col sm="12" class="mt-3">
            <div class="left-pos d-flex align-items-center">
              <div
                class="logo d-flex"
                style="cursor: pointer"
                @click="$router.push('/')"
              >
                <b-img src="/img/logodark.svg" width="50%" alt="" />
              </div>
              <div class="orgname1 d-flex align-items-center">
                {{ $t("publiceducation") }}
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <b-row>
              <b-col sm="12" md="5" class="mt-3">
                <img src="/img/footer-app.png" alt="" />
              </b-col>
              <b-col sm="12" md="7" class="mt-3 d-flex align-items-center">
                <div>
                  <h2 style="font-family:Montserrat-extrabold;" class="mb-3">
                    {{ $t("mobileApp") }}
                  </h2>
                  <span> {{ $t("AllInfoInPhone") }} </span>
                  <b-row class="mt-3">
                    <b-col>
                      <a
                        href="https://apps.apple.com/us/app/govcontrol-uz/id1630070272"
                        target="_blank"
                      >
                        <img
                          width="100%"
                          src="https://click.uz/click/images/evo2.svg"
                          alt=""
                        />
                      </a>
                    </b-col>
                    <b-col class="pl-0">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.bo.govcontroluz"
                        target="_blank"
                      >
                        <img
                          width="100%"
                          src="https://click.uz/click/images/evo1.svg"
                          alt=""
                        />
                      </a>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="6">
            <h3 style="font-family:Montserrat-bold;">
              {{ $t("connectwithus") }}
            </h3>
            <div class="d-flex my-1">
              <img style="margin-right:15px" src="/img/phone-icon.svg" alt="" />
              <span> (71) 202 08 97 </span>
            </div>
            <div class="d-flex my-1">
              <img style="margin-right:15px" src="/img/mail-icon.svg" alt="" />
              <span> info@biznesombudsman.uz </span>
            </div>
            <div class="d-flex my-1">
              <img style="margin-right:15px" src="/img/geo-icon.svg" alt="" />
              <span> {{ $t("addressus") }} </span>
            </div>
            <h3 style="font-family:Montserrat-bold;">{{ $t("links") }}</h3>
            <div class="d-flex my-1">
              <span>
                1. {{ $t("forinspector") }}:
                <a href="http://tt.govcontrol.uz" class="text-white">
                  http://tt.govcontrol.uz
                </a>
              </span>
            </div>

            <div class="d-flex my-1" style="cursor:pointer">
              <img
                style="margin-right:15px"
                src="/img/facebook-icon.svg"
                @click="GotoUrl('https://facebook.com/biznes.ombudsman')"
                alt=""
              />
              <img
                style="margin-right:15px"
                src="/img/telegram-icon.svg"
                @click="GotoUrl('https://t.me/biznes_ombudsman')"
                alt=""
              />
              <img
                style="margin-right:15px"
                src="/img/instagram-icon.svg"
                @click="GotoUrl('https://instagram.com/biznes_ombudsman')"
                alt=""
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-sidebar v-model="sidebar" no-header width="100%" right>
      <div class="w-100 h-100 bg-white">
        <div class="cl-stages-accepting-applications">
          <div class="row justify-content-between pb-5">
            <div class="news-date mt-5 mb-3 px-2">
              <b-icon icon="calendar2-date"></b-icon
              ><span style="margin-right:15px;margin-left:2px">{{
                NewsInfo.date
              }}</span>
              <b-icon icon="eye"></b-icon
              ><span style="margin-left:2px">{{ NewsInfo.viewCount }}</span>
            </div>
            <p
              class="news-description px-2 mb-1 text-center"
              style="font-size:22px;font-weight:bold"
            >
              {{ NewsInfo.title }}
            </p>
            <!-- <div class="a" v-html="NewsInfo.shortContent"></div> -->
            <div v-html="NewsInfo.content" class="mb-3 b"></div>
            <b-row>
              <b-col
                sm="12"
                lg="1"
                v-for="(item, index) in NewsInfo.tags"
                :key="index"
              >
                <a style="color:#0C2F75" href="">{{ item.name }}</a>
              </b-col>
            </b-row>
          </div>
        </div>
        <span
          class="close-btn"
          style="position: absolute; top: 0px; right: 0px; cursor: pointer"
          @click="sidebar = false"
        >
          <img src="images/design/fill-close.svg" alt="" />
        </span>
      </div>
    </b-sidebar>
    <div class="gototop" @click="topFunction">
      <b-icon icon="chevron-up"></b-icon>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BSidebar,
  BModal,
  BSpinner,
  BButton,
  BCarousel,
  BCarouselSlide,
  BIconX,
  BIconArrowLeft,
} from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import customOutlineButton from "../components/elements/customButtonOutline.vue";
import customProgress from "../components/elements/customProgress.vue";
import customInput from "../components/elements/customInput.vue";
import customSelect from "../components/elements/customSelect.vue";
import customButton from "../components/elements/customButton.vue";
import AccountIcon from "../components/custom-icons/accountIcon.vue";
import admIcon from "../components/custom-icons/admIcon.vue";
import childIcon from "../components/custom-icons/childIcon.vue";
import NewsService from "@/services/news.service";
import VueSlickCarousel from "vue-slick-carousel";
import vSelect from "vue-select";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { LazyYoutube, LazyVimeo } from "vue-lazytube";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import axios from "axios";
import ManualService from "@/services/manual.service";
import ControlFunctionService from "@/services/controlfunction.service";
import VideoCategoryService from "@/services/videocategory.service";
import VideoLessonService from "@/services/videolesson.service";
import MandatoryRequirementService from "@/services/mandatoryrequirement.service";
import HealthRiskFactorService from "@/services/healthriskfactor.service";
import LandingPageService from "../services/landingpage.service";
import AOS from "aos";
import "aos/dist/aos.css";
export default {
  components: {
    BRow,
    BCol,
    BImg,
    BSidebar,
    customOutlineButton,
    customProgress,
    AccountIcon,
    admIcon,
    childIcon,
    customInput,
    customSelect,
    BModal,
    customButton,
    BSpinner,
    BButton,
    BCarousel,
    BCarouselSlide,
    VueSlickCarousel,
    BIconX,
    BIconArrowLeft,
    vSelect,
    LazyYoutube,
    LazyVimeo,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      test1: 176,
      test2: 5353,
      sidebar1: false,
      sidebar2: false,
      newsSidebar: false,
      instruction: false,
      StatisticList: [],
      tabIndex: 1,
      videoshow: false,
      allstatistic: false,
      OblastList: [],
      RegionList: [],
      MfyList: [],
      EducationLanguageList: [],
      StreetList: [],
      StreetHouseList: [],
      infoContent: "",
      hudud: false,
      FindSchoolloading: false,
      SearchByNameLoading: false,
      isOpenSchoolInput: false,
      SchoolList: [],
      slide: 0,
      sliding: false,
      News: [],
      NewsInfo: {},
      filter: {
        orderType: "",
        page: 1,
        pageSize: 3,
        search: "",
        sortBy: "",
      },
      axios,
      sidebar: false,
      settings: {
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
      },
      OrganizationList: [],
      ControlFuncLoading: false,
      ControlFuncList: [],
      ControlFuncFilter: {
        parentOrganizationId: 0,
        byOked: false,
        parentId: "",
        authorizedOnly: false,
        inspectionOnly: false,
        orderType: "asc",
        page: 1,
        pageSize: 2,
        sortBy: "",
        totalRows: 0,
      },
      ControlFuncFilters: {
        parentOrganizationId: {
          matchMode: "equals",
          value: 0,
          text: "",
        },
      },
      VideoCategoryList: [],
      videocategory: 0,
      videolesson: [],
      MandatoryRequirementLoading: false,
      HealthRiskFactorLoading: false,
      MandatoryRequirementFilters: {
        parentOrganizationId: {
          matchMode: "equals",
          value: 0,
          text: "",
        },
      },
      MandatoryRequirementFilter: {
        byOked: false,
        level: 5,
        orderType: "asc",
        page: 1,
        pageSize: 4,
        sortBy: "",
        parentId: "",
        authorizedOnly: false,
        inspectionOnly: false,
        totalRows: 0,
      },
      MandatoryRequirement: [],
      HealthRiskFactorFilters: {
        parentOrganizationId: {
          matchMode: "equals",
          value: 0,
          text: "",
        },
      },
      HealthRiskFactorFilter: {
        byOked: false,
        level: 5,
        orderType: "asc",
        page: 1,
        pageSize: 4,
        sortBy: "",
        parentId: "",
        authorizedOnly: false,
        inspectionOnly: false,
        totalRows: 0,
      },
      HealthRiskFactor: [],
      PageData: {},
      scaleClass: false,
    };
  },
  created() {
    AOS.init();
    this.getNews();
    this.getOrganizationList();
    // this.ControlFuncRefresh()
    VideoCategoryService.GetAsSelectList().then((res) => {
      this.VideoCategoryList = res.data;
      this.videocategory = this.VideoCategoryList[0].value;
      this.GetVideoLesson(this.VideoCategoryList[0]);
    });
    // this.MandatoryRequirementRefresh()
    LandingPageService.GetPageData().then((res) => {
      this.PageData = res.data;
    });
    window.addEventListener("scroll", this.handleScroll);
    this.playAnimation();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    lang() {
      return localStorage.getItem("locale") || "uz_cyrl";
    },
  },
  methods: {
    GotoUrl(url) {
      window.open(url, "_blank");
    },
    handleScroll(event) {
      if (document.documentElement.scrollTop < 450) {
      }
      if (
        document.documentElement.scrollTop >= 450 &&
        document.documentElement.scrollTop < 1000
      ) {
      }

      if (
        document.documentElement.scrollTop >= 1000 &&
        document.documentElement.scrollTop < 2400
      ) {
        this.playAnimation();
      }
      if (document.documentElement.scrollTop >= 2400) {
      }
    },
    completed() {
      this.scaleClass = true;
    },
    playAnimation() {
      this.scaleClass = false;
      this.$refs.number2.play();
      this.$refs.number3.play();

      this.$refs.number70[0].play();
      this.$refs.number71[0].play();
      this.$refs.number72[0].play();
      this.$refs.number73[0].play();
      this.$refs.number74[0].play();
      this.$refs.number75[0].play();
      this.$refs.number76[0].play();
      this.$refs.number77[0].play();
      this.$refs.number78[0].play();
      this.$refs.number79[0].play();
    },
    CounterFunc(number) {
      const speed = 200;
      number.forEach((counter) => {
        const animate = () => {
          const value = +counter.getAttribute("akhi");
          const data = +counter.innerText;

          const time = value / speed;
          if (data < value) {
            counter.innerText = Math.ceil(data + time);
            setTimeout(animate, 1);
          } else {
            counter.innerText = value;
          }
        };
        animate();
      });
    },
    MandatoryRequirementRefresh() {
      this.MandatoryRequirementLoading = true;
      if (!!this.MandatoryRequirementFilters.parentOrganizationId.value) {
        this.MandatoryRequirementFilter.filters = this.MandatoryRequirementFilters;
      }
      MandatoryRequirementService.GetListLandingPage(
        this.MandatoryRequirementFilter
      )
        .then((res) => {
          this.MandatoryRequirement = res.data.rows;
          this.MandatoryRequirementLoading = false;
          this.MandatoryRequirementFilter.totalRows = res.data.total;
        })
        .catch((error) => {
          this.MandatoryRequirementLoading = false;
          this.makeToast(error.response.data.errors, "error");
        });
    },
    HealthRiskFactorRefresh() {
      this.HealthRiskFactorLoading = true;
      if (!!this.HealthRiskFactorFilters.parentOrganizationId.value) {
        this.HealthRiskFactorFilter.filters = this.HealthRiskFactorFilters;
      }
      HealthRiskFactorService.GetList(this.HealthRiskFactorFilter)
        .then((res) => {
          this.HealthRiskFactor = res.data.rows;
          this.HealthRiskFactorLoading = false;
          this.HealthRiskFactorFilter.totalRows = res.data.total;
        })
        .catch((error) => {
          this.HealthRiskFactorLoading = false;
          this.makeToast(error.response.data.errors, "error");
        });
    },
    SetVideoCategory(item) {
      this.videocategory = item.value;
      this.GetVideoLesson(item);
    },
    GetVideoLesson(item) {
      VideoLessonService.GetList({
        filters: {
          categoryId: {
            value: item.value,
            matchMode: "equals",
          },
        },
        search: "",
        sortBy: "",
        orderType: "asc",
        page: 1,
        pageSize: 100,
      })
        .then((res) => {
          this.videolesson = res.data.rows;
          this.filter.totalRows = res.data.total;
        })
        .catch((error) => {
          this.Loading = false;
          this.makeToast(error.response.data, "error");
        });
    },
    ControlFuncRefresh() {
      this.ControlFuncLoading = true;
      if (!!this.ControlFuncFilters.parentOrganizationId.value) {
        this.ControlFuncFilter.filters = this.ControlFuncFilters;
      }
      ControlFunctionService.GetListLandingPage(this.ControlFuncFilter)
        .then((res) => {
          this.ControlFuncList = res.data.rows;
          this.ControlFuncFilter.totalRows = res.data.total;
          this.ControlFuncLoading = false;
        })
        .catch((error) => {
          this.ControlFuncLoading = false;
          this.makeToast(error.response.data.errors, "error");
        });
    },
    getOrganizationList() {
      ManualService.GetOrganizationAsSelectList("", false, false).then(
        (res) => {
          this.OrganizationList = res.data;
        }
      );
    },
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    bindlis() {
      document
        .getElementById("gotoschool")
        .addEventListener("click", this.GotoSchoolInfo);
    },
    QuotaWidth(item) {
      if (item.Quota > item.ApplicantCount) {
        return "100%";
      }
      if (item.Quota < item.ApplicantCount && item.Quota != 0) {
        return (item.Quota * 100) / item.ApplicantCount + "%";
      }
      if (item.Quota == 0) {
        return "0%";
      }
    },
    ApplicationWidth(item) {
      if (item.Quota < item.ApplicantCount) {
        return "100%";
      }
      if (item.Quota > item.ApplicantCount && item.ApplicantCount != 0) {
        return (item.ApplicantCount * 100) / item.Quota + "%";
      }
      if (item.ApplicantCount == 0) {
        return "0%";
      }
    },
    closeschoolinfo() {
      this.isOpenSchoolInput = false;
    },
    FindByName(item) {
      this.isOpenSchoolInput = false;
      this.getLocationSchool(item);
      this.map.center = {
        lat: this.map.data.latitude,
        lng: this.map.data.longitude,
      };
    },
    InfoBoxSave() {
      localStorage.setItem("infobox", 1);
      this.$store.state.infobox = false;
    },
    CloseAllStatistic() {
      this.allstatistic = true;
    },
    ChangeOblast() {
      this.filter.regionid = null;
      this.filter.mfyid = null;
      this.filter.streetid = null;
      this.filter.streethouseid = null;
      this.filter.streethousename = "";
      this.getRegionList();
    },
    Register() {
      this.$store.state.isOpenSidebar = !this.$store.state.isOpenSidebar;
      localStorage.removeItem("admtype");
      this.$store.state.admtype = 0;
    },
    CloseInstruction() {
      this.instruction = false;
      this.$refs.videoRef.pause();
    },
    VideoShow() {
      if (this.videoshow) {
        this.videoshow = false;
        this.$refs.videoRef.pause();
      }
      if (!this.videoshow) {
        this.videoshow = true;
        this.$refs.videoRef.play();
      }
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    OpenSidebar() {
      this.$store.state.isOpenSidebar = true;
    },
    getNews() {
      NewsService.GetList(this.filter)
        .then((res) => {
          this.News = res.data.rows;
        })
        .catch((error) => {
          this.Loading = false;
          this.$message(error.response.data);
        });
    },
    GetInfo(item) {
      this.sidebar = true;
      NewsService.Get(item.id).then((res) => {
        this.NewsInfo = res.data;
      });
    },
    makeToast(message, type) {
      var a = "";
      if (message.status == 500) {
        a = message.title;
      }
      if (message.status == 400) {
        var errors = Object.values(message.errors);
        var a = errors.map((el, item) => item + 1 + "." + el).join("\n");
      } else {
        a = message;
      }
      this.$toast.open({
        message: a,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
  },
};
</script>

<style>
.gototop {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.orgname1 {
  font-family: Montserrat-extrabold;
  width: 230px;
  font-size: 20px;
  margin-left: 20px;
  text-transform: uppercase;
}
.my-input1 {
  width: 100%;
  padding: 13px 20px;
  border: none;
  outline: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
}
.my-bg-primary-dark {
  background-color: #5965ce;
  color: #fff;
}

.my-select .vs__dropdown-toggle {
  padding: 12px 10px !important;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
}
.my-news-bg {
  background: rgb(15, 46, 202);
  background: linear-gradient(
    90deg,
    rgba(15, 46, 202, 1) 0%,
    rgba(15, 46, 202, 0) 100%
  );
}
.image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.cardStyle {
  height: 500px;
  cursor: pointer;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.news-text {
  font-family: Montserrat-bold;
  font-size: 20px;
}
.news-text > *,
.news-text p strong {
  font-family: Montserrat-bold;
  font-size: 20px;
}
.news-text:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
