<template>
  <div class="my-register-page mb-5">
    <div class="my-container">
      <b-row class="mb-2 mt-3 pt-3">
        <b-col sm="12" md="10">
          <h1>{{ $t("obligations") }}</h1>
        </b-col>
        <b-col class="mt-4" style="text-align:right">
          <b-button
            style="width:100px"
            @click="$router.go(-1)"
            variant="outline-danger"
          >
            {{ $t("back") }}
          </b-button>
        </b-col>
      </b-row>

      <b-table-simple bordered>
        <b-thead class="text-center">
          <b-tr variant="primary">
            <b-th style="vertical-align: middle">
              {{ $t("obligationsOfEntrepreneur") }}
            </b-th>

            <b-th style="vertical-align: middle">
              {{ $t("yes") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("no") }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in Obligations" :key="index">
            <b-td class="text-nowrap" style="vertical-align: middle">
              {{ item.obligationsOfEntrepreneur }}
            </b-td>

            <b-td class="text-center" @click="item.isCheck = !item.isCheck">
              <svg
                v-if="item.isCheck"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="green"
                class="bi bi-check-circle"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                />
                <path
                  d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"
                />
              </svg>
            </b-td>
            <b-td class="text-center" @click="item.isCheck = !item.isCheck">
              <svg
                v-if="!item.isCheck"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="red"
                class="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                />
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div style="float:right">
        <b-button @click="SaveData" variant="outline-success">
          {{ $t("Save") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ObligationsService from "@/services/obligations.service";
import customSelect from "../../components/elements/customSelect.vue";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BIconEyeFill,
  BPagination,
} from "bootstrap-vue";
export default {
  data() {
    return {
      Obligations: [],
      filter: {
        contractorId: null,
      },
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BTableSimple,
    BIconEyeFill,
    BPagination,
    customSelect,
  },
  created() {
    this.filter.contractorId = JSON.parse(
      localStorage.getItem("user_info")
    ).contractorId;
    console.log(JSON.parse(localStorage.getItem("user_info")).contractorId);
    this.Refresh();
  },
  methods: {
    SaveData() {
      const updateList = this.Obligations.filter((item) => item.id !== 0);
      const createList = this.Obligations.filter((item) => item.id === 0);

      const updatePromise = updateList.length
        ? ObligationsService.Update(updateList)
        : Promise.resolve();

      const createPromise = createList.length
        ? ObligationsService.Create(createList)
        : Promise.resolve();

      Promise.all([updatePromise, createPromise])
        .then(() => {
          this.$router.push({ name: "Cabinet" });
          this.makeToast(this.$t("SaveSuccess"), "success");
        })
        .catch((error) => {
          this.makeToast(error.response?.data || error.message, "error");
        });
    },
    Refresh() {
      ObligationsService.GetList(this.filter)
        .then((res) => {
          this.Obligations = res.data;
        })
        .catch((error) => {
          this.Loading = false;
          this.$message(error.response.data);
        });
    },
  },
};
</script>

<style></style>
