import Vue from "vue";
import App from "./App.vue";
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from "./store";
import * as VueGoogleMaps from "vue2-google-maps";
import axios from "axios";
import VueMask from "v-mask";
import "vue2-datepicker/index.css";
import router from "./router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { VueMaskDirective } from "v-mask";
import "./assets/styles/main.scss";
import "./assets/styles/base.scss";
import "./assets/styles/info.scss";
import "vue-select/dist/vue-select.css";
// import './plugins/e-imzo'
// import './plugins/e-imzo-client'
import i18n from "./lang/index.js";
import VueToast from "vue-toast-notification";
import ApiService from "./services/api.service";

import VueNumber from "vue-number-animation";

Vue.use(VueNumber);

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import LazyTube from "vue-lazytube";
Vue.use(LazyTube);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import { ModalPlugin } from "bootstrap-vue";
// import 'vue-toast-notification/dist/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css";
Vue.directive("mask", VueMaskDirective);
Vue.use(VueMask);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueMask);
Vue.use(ModalPlugin);
import wb from "./registerServiceWorker.js";
Vue.prototype.$workbox = wb;
axios.defaults.baseURL = "http://gov-control-my-api.apptest.uz/";
// axios.defaults.baseURL = "http://my-api.govcontrol.uz/";
// axios.defaults.baseURL = 'https://mynext-api.maktab.uz/'
// axios.defaults.baseURL = 'http://localhost:5000/'
// axios.defaults.baseURL = "https://my-api.govcontrol.uz/";
if (window.location.href.indexOf("https://my.govcontrol.uz") > -1) {
  axios.defaults.baseURL = "https://my-api.govcontrol.uz/";
}
if (window.location.href.indexOf("http://my.govcontrol.uz") > -1) {
  axios.defaults.baseURL = "http://my-api.govcontrol.uz/";
}
if (window.location.href.indexOf("http://govcontrol.uz/") > -1) {
  axios.defaults.baseURL = "http://my-api.govcontrol.uz/";
}
if (window.location.href.indexOf("https://govcontrol.uz/") > -1) {
  axios.defaults.baseURL = "https://my-api.govcontrol.uz/";
}
if (window.location.href.indexOf("https://admnext.maktab") > -1) {
  axios.defaults.baseURL = "https://admnext-api.maktab.uz/";
}
if (window.location.href.indexOf("https://mynext.maktab") > -1) {
  axios.defaults.baseURL = "https://mynext-api.maktab.uz/";
}
var lang = localStorage.getItem("locale");
if (lang == "ru") {
  axios.defaults.params = {
    __lang: "ru",
  };
} else if (lang == "uz_latn") {
  axios.defaults.params = {
    __lang: "uz-latn",
  };
} else {
  axios.defaults.params = {
    __lang: "uz-cyrl",
  };
}

Vue.use(VueToast, {
  position: "top",
});
const requestInterceptor = (request) => {
  request.withCredentials = true;
  return request;
};
localStorage.getItem("locale") === null
  ? localStorage.setItem("locale", "uz_cyrl")
  : "";
axios.interceptors.request.use((request) => requestInterceptor(request));
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBJezTcA6efW5eT6ADhaaTB2v9U0R9RQCk",
    // key:'AIzaSyBN6gdq_dI68OK4atPtDPA2gLP_MCOgSAE',
    libraries: "places",
    async: true,
    defer: true,
  },
});
import YmapPlugin from "vue-yandex-maps";

Vue.use(YmapPlugin, {
  apiKey: "bcfe0f65-99fb-4c9a-b827-1560e399995c",
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1",
});
Vue.config.productionTip = false;
ApiService.mount401Interceptor();
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
