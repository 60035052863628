<template>
  <div class="my-register-page">
    <div class="my-container">
      <b-row class="mt-4">
        <b-col sm="12" md="12" lg="12">
          <label for></label>
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between">
              <span> {{ $t("authorizedOrganization") }}: </span>
              <span style="width: 80%; font-size: 15px">
                <b> {{ Complaint.authorizedOrganization }} </b>
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col sm="12" md="6" lg="6">
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between">
              <span> {{ $t("requestDocNumber") }}: </span>
              <span>
                <b> {{ Complaint.requestDocNumber }} </b>
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col sm="12" md="6" lg="6">
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between">
              <span> {{ $t("requestDocDate") }}: </span>
              <span>
                <b> {{ Complaint.requestDocDate }} </b>
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col sm="12" md="6" lg="6">
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between">
              <span> {{ $t("orderedOrganization") }}: </span>
              <span>
                <b> {{ Complaint.orderedOrganization }} </b>
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col sm="12" md="6" lg="6">
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between">
              <span> {{ $t("inspectionOrganization") }}: </span>
              <span>
                <b> {{ Complaint.inspectionOrganization }} </b>
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col sm="12" md="6" lg="6">
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between">
              <span> {{ $t("ComplaintdocDate") }}: </span>
              <span>
                <b> {{ Complaint.docDate }} </b>
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <!-- <b-row class="mt-4">
        <b-col sm="12" md="6" lg="6">
          <label for>{{ $t("docDate") }}</label>
          <custom-date-picker
            v-model="Complaint.docDate"
            @keyup="docDateValue"
            format="DD.MM.YYYY"
            type="date"
            :clearable="false"
            :placeholder="$t('docdate')"
            disabled
          >
          </custom-date-picker>
        </b-col>
        
      </b-row> -->
      <b-row class="mt-2">
        <b-col sm="12" md="12" lg="12">
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between">
              <span> {{ $t("complaintText") }}: </span>
              <span style="width: 80%; font-size: 15px">
                <b> {{ Complaint.complaintText }} </b>
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col sm="12" md="12" lg="12">
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between">
              <span> {{ $t("suggestion") }}: </span>
              <span style="width: 80%; font-size: 15px">
                <b> {{ Complaint.suggestion }} </b>
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col align="left" sm="12" md="6" lg="6" class="mt-1">
          <b-button @click="back" style="width: 150px" variant="outline-danger">
            {{ $t("back") }}
          </b-button></b-col
        >
        <b-col
          v-if="Complaint.statusId != 2"
          align="right"
          sm="12"
          md="6"
          lg="6"
          class="text-right"
        >
          <b-button @click="Send" variant="outline-success">
            <feather-icon icon="CheckIcon"></feather-icon> {{ $t("Send") }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import ComplaintService from "@/services/complaint.service";
import CustomDatePicker from "../../components/customDatePicker.vue";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormInput,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import CustomTextarea from "../../components/elements/customTextarea.vue";
export default {
  data() {
    return {
      GetByRequestIdLoading: false,
      Complaint: {},
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormInput,
    CustomDatePicker,
    CustomTextarea,
    BListGroup,
    BListGroupItem,
  },
  created() {
    this.Refresh();
  },
  methods: {
    Refresh() {
      ComplaintService.Get(this.$route.params.id)
        .then((res) => {
          this.Complaint = res.data;
        })
        .catch((error) => {
          this.makeToast(error.response.data, "error");
        });
    },
    makeToast(message, type) {
      var a = "";
      if (message.status == 500) {
        a = message.title;
      }
      if (message.status == 400) {
        var errors = Object.values(message.errors);
        var a = errors.map((el, item) => item + 1 + "." + el).join("\n");
      } else {
        a = message;
      }
      this.$toast.open({
        message: a,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
    docDateValue(value) {
      this.Complaint.docDate = value;
    },
    // requestDocDateValue(value) {
    //   this.Complaint.requestDocDate = value;
    // },
    back() {
      // this.$router.push({ name: "complaint" });
      this.$router.go(-1);
    },
    Send() {
      ComplaintService.Send(this.Complaint)
        .then((res) => {
          this.makeToast(this.$t("SaveSuccess"), "success");
          this.$router.push({ name: `complaint` });
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast(error.response.data.errors, "error");
        });
    },
  },
};
</script>
<style></style>
