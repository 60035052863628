<template>
  <div style="position: relative">
    <nav class="my-navbar d-flex justify-content-between ">
      <div class="container d-flex">
        <div class="left-pos d-flex align-items-center">
          <div
            class="logo d-flex"
            style="cursor: pointer"
            @click="$router.push('/')"
          >
            <b-img src="/img/logo.svg" width="50%" alt="" />
          </div>
          <div class="orgname d-flex align-items-center my-2">
            {{ $t("publiceducation") }}
          </div>
        </div>
        <div class="right-pos d-flex justify-content-end">
          <div
            v-if="$route.name == 'Home'"
            class="account  d-flex align-items-center justify-content-center mt-2"
            @click="topFunction"
          >
            <p
              :class="
                $store.state.menu_item == 'main' ? 'menu_active_color' : ''
              "
            >
              {{ $t("Main") }}
            </p>
          </div>
          <div
            v-if="$route.name == 'Home'"
            class="account  d-flex align-items-center justify-content-center mt-2"
          >
            <p @click="$store.state.menu_item = 'news'">
              <a
                style="text-decoration:none;color:#000"
                :class="
                  $store.state.menu_item == 'news' ? 'menu_active_color' : ''
                "
                href="#news"
                >{{ $t("News1") }}</a
              >
            </p>
          </div>
          <div
            v-if="$route.name == 'Home'"
            class="account  d-flex align-items-center justify-content-center mt-2"
          >
            <p @click="$store.state.menu_item = 'ControlFunction'">
              <a
                style="text-decoration:none;color:#000"
                :class="
                  $store.state.menu_item == 'ControlFunction'
                    ? 'menu_active_color'
                    : ''
                "
                href="#ControlFunction"
                >{{ $t("ControlFunction") }}</a
              >
            </p>
          </div>
          <!-- <div
            v-if="$route.name == 'Home'"
            class="account  d-flex align-items-center justify-content-center mt-2"
          >
            <p @click="$store.state.menu_item = 'MandRequirement'">
              <a
                style="text-decoration:none;color:#000"
                :class="
                  $store.state.menu_item == 'MandRequirement'
                    ? 'menu_active_color'
                    : ''
                "
                href="#MandRequirement"
                >{{ $t("MandRequirement") }}</a
              >
            </p>
          </div> -->
          <div
            v-if="$route.name == 'Home'"
            class="account  d-flex align-items-center justify-content-center mt-2"
          >
            <p @click="$store.state.menu_item = 'HealthRiskFactor'">
              <a
                style="text-decoration:none;color:#000"
                :class="
                  $store.state.menu_item == 'MandRequirement'
                    ? 'menu_active_color'
                    : ''
                "
                href="#MandRequirement"
                >{{ $t("MandRequirement") }}</a
              >
            </p>
          </div>

          <div
            v-if="$route.name == 'Home'"
            class="account  d-flex align-items-center justify-content-center mt-2"
          >
            <p @click="$store.state.menu_item = 'Videodarsliklar'">
              <a
                style="text-decoration:none;color:#000"
                :class="
                  $store.state.menu_item == 'Videodarsliklar'
                    ? 'menu_active_color'
                    : ''
                "
                href="#Videodarsliklar"
                >{{ $t("Videodarsliklar") }}</a
              >
            </p>
          </div>

          <div
            v-if="$route.name != 'Home'"
            class="account bell d-flex align-items-center justify-content-center mt-2"
          >
            <p class="usernameStyle ">{{ infoName }}</p>
          </div>

          <div class="language d-flex align-items-center">
            <div class="my-dropdown d-inline-block">
              <div
                class="my-dropdown-title"
                @click="isOpenDropdown = !isOpenDropdown"
              >
                {{ $t("lang") }}
              </div>
              <transition name="dropdown-open">
                <div
                  class="dropdown-content"
                  v-if="isOpenDropdown"
                  v-click-outside="close"
                >
                  <ul class="m-0">
                    <li
                      @click="ChangeLang('uz_cyrl')"
                      :class="{ active: lang === 'uz_cyrl' }"
                    >
                      Ўзбек
                    </li>
                    <li
                      @click="ChangeLang('ru')"
                      :class="{ active: lang === 'ru' }"
                    >
                      Русский
                    </li>
                    <li
                      @click="ChangeLang('uz_latn')"
                      :class="{ active: lang === 'uz_latn' }"
                    >
                      O’zbek
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
          </div>
          <div
            class="account d-flex align-items-center justify-content-center"
            @click="OpenSidebar"
          >
            <b-img src="/images/design/account.svg" />
          </div>
        </div>
      </div>
    </nav>
    <b-sidebar
      no-header
      width="400px"
      shadow
      right
      v-model="$store.state.isOpenSidebar"
      bg-variant="white"
    >
      <div style="width: 100%; height: 100%">
        <div class="container-fluid">
          <b-row>
            <b-col class="text-right close-icon">
              <b-img
                @click="$store.state.isOpenSidebar = false"
                src="/images/design/close.svg"
              />
            </b-col>
          </b-row>
          <b-row v-if="!isToken">
            <b-col
              class="d-flex align-items-center my-sidebar-body"
              style="position: relative; overflow-y: auto; margin-bottom: 60px"
            >
              <div>
                <b-row>
                  <b-col>
                    <p class="text-sign text-primary-dark">{{ $t("enter") }}</p>
                    <p class="text-info-sign text-primary-dark">
                      {{ $t("usephonenumber") }}
                    </p>
                  </b-col>
                </b-row>
                <b-row style="margin-bottom: 16px">
                  <b-col sm="12">
                    <custom-input
                      :label="$t('phonenumber')"
                      @keyup="ChangePhone"
                      @keyup.native.enter="isCheckAccount"
                      v-mask="'+998-##-###-##-##'"
                      v-model="Account.phonenumber"
                      placeholder="+998 _ _  _ _ _  _ _  _ _"
                    >
                    </custom-input>
                  </b-col>
                </b-row>
                <b-row
                  v-if="
                    filter.isPassword === null && filter.isSmsCode === false
                  "
                >
                  <b-col>
                    <custom-button @click.native="isCheckAccount" block>
                      <b-spinner
                        v-if="checkAccountLoading"
                        small
                        style="margin-right: 8px"
                      ></b-spinner>
                      {{ $t("register") }}
                    </custom-button>
                  </b-col>
                </b-row>
                <b-row
                  v-if="filter.isPassword === true"
                  style="margin-bottom: 16px"
                >
                  <b-col sm="12">
                    <custom-input
                      type="password"
                      @keyup.native.enter="SignIn"
                      v-model="Account.password"
                      :label="$t('password')"
                      placeholder="****"
                    ></custom-input>
                  </b-col>
                  <b-col sm="12" class="mt-2 d-flex justify-content-end">
                    <a
                      @click="OpenForgotPass"
                      class="cursor-pointer"
                      style="color: #003d45; text-decoration: none"
                    >
                      {{ $t("forgotpassword") }}
                    </a>
                  </b-col>
                </b-row>
                <b-row v-if="filter.isSmsCode" style="margin-bottom: 16px">
                  <b-col sm="12"> </b-col>
                  <b-col sm="12">
                    <custom-input
                      v-mask="'####'"
                      v-model="Account.smscode"
                      :label="$t('smskod')"
                      placeholder="1234"
                    ></custom-input>
                  </b-col>
                </b-row>
                <b-row v-if="filter.isPassword === true">
                  <b-col>
                    <custom-button @click.native="SignIn" block>
                      <b-spinner
                        v-if="SignLoading"
                        small
                        style="margin-right: 8px"
                      ></b-spinner>
                      {{ $t("auth") }}
                    </custom-button>
                  </b-col>
                </b-row>
                <b-row v-if="filter.isPassword === false">
                  <b-col
                    sm="12"
                    style="margin-bottom: 16px"
                    class="text-center"
                  >
                    <span class="text-center text-danger">
                      {{ $t("goregister") }}
                    </span>
                  </b-col>
                  <b-col sm="12" style="margin-bottom: 16px">
                    <custom-input
                      type="password"
                      v-model="Account.password"
                      :label="$t('password')"
                      placeholder="******"
                    ></custom-input>
                  </b-col>
                  <b-col sm="12" style="margin-bottom: 16px">
                    <custom-input
                      type="password"
                      v-model="Account.passwordconfirm"
                      :label="$t('confirmpassword')"
                      placeholder="******"
                    ></custom-input>
                  </b-col>
                  <b-col
                    sm="12"
                    v-if="filter.isSmsForRegister"
                    style="margin-bottom: 16px"
                    class="text-center"
                  >
                    <span class="text-center text-danger">
                      {{
                        $t("lang") == "Ру"
                          ? $t("entersmscodesentnumber") +
                            " " +
                            Account.phonenumber
                          : Account.phonenumber +
                            " " +
                            $t("entersmscodesentnumber")
                      }}
                    </span>
                  </b-col>
                  <b-col
                    sm="12"
                    v-if="filter.isSmsForRegister"
                    style="margin-bottom: 16px"
                  >
                    <custom-input
                      v-mask="'####'"
                      v-model="Account.smscode"
                      :label="$t('smskod')"
                      placeholder="1234"
                    ></custom-input>
                  </b-col>
                  <b-col sm="12" v-if="!filter.isSms">
                    <custom-button @click.native="GiveSmsCode" block>
                      <b-spinner
                        v-if="SignLoading"
                        small
                        style="margin-right: 8px"
                      ></b-spinner>
                      {{ $t("Smskodloish") }}
                    </custom-button>
                  </b-col>
                  <b-col sm="12" v-if="filter.isSmsForRegister">
                    <custom-button @click.native="SendSmsCode" block>
                      <b-spinner
                        v-if="SignLoading"
                        small
                        style="margin-right: 8px"
                      ></b-spinner>
                      {{ $t("registration") }}
                    </custom-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="isToken">
            <b-col cols="12">
              <span class="d-block" style="font-size: 28px; margin-left: 15px">
                {{ getUserName }}
              </span>
              <div
                class="mt-5 d-flex align-items-center"
                style="cursor: pointer"
                @click="GoCabinet"
              >
                <b-img
                  width="25%"
                  style="
                    margin-left: 15px;
                    margin-right: 5px;
                    display: inline;
                    cursor: pointer;
                  "
                  src="/img/acc-login.svg"
                ></b-img>
                <span style="margin-left: 8px"> {{ $t("mycabinet") }} </span>
              </div>
              <div
                class="mt-3 d-flex align-items-center"
                style="cursor: pointer"
                @click="GoMyInfo"
              >
                <b-img
                  width="30%"
                  style="
                    margin-left: 15px;
                    margin-right: 5px;
                    display: inline;
                    cursor: pointer;
                  "
                  src="/img/my_info.svg"
                ></b-img>
                <span style="margin-left: 8px"> {{ $t("myinfo") }} </span>
              </div>
              <div
                class="mt-3 d-flex align-items-center"
                style="cursor: pointer"
                @click="GoObligations"
              >
                <b-img
                  width="30%"
                  style="
                    margin-left: 15px;
                    margin-right: 5px;
                    display: inline;
                    cursor: pointer;
                  "
                  src="/img/my_info.svg"
                ></b-img>
                <span style="margin-left: 8px"> {{ $t("obligations") }} </span>
              </div>

              <div
                class="mt-3 d-flex align-items-center"
                style="cursor: pointer"
                @click="GoComplaint"
              >
                <b-img
                  width="30%"
                  style="
                    margin-left: 15px;
                    margin-right: 5px;
                    display: inline;
                    cursor: pointer;
                  "
                  src="/img/shikoyat.svg"
                ></b-img>
                <span style="margin-left: 8px"> {{ $t("Complaint") }} </span>
              </div>

              <div
                class="mt-3 d-flex align-items-center"
                style="cursor: pointer"
                @click="Logout"
              >
                <b-img
                  width="25%"
                  style="
                    margin-left: 15px;
                    margin-right: 5px;
                    display: inline;
                    cursor: pointer;
                  "
                  src="/img/logout.svg"
                ></b-img>
                <span style="margin-left: 8px"> {{ $t("logout") }} </span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-sidebar>
    <custom-dialog v-model="$store.state.isOpenSearch" height="128px">
      <div class="my-top-search">
        <div class="my-top-search-container">
          <input
            type="text"
            class="my-search-input"
            :placeholder="$t('Search')"
          />
        </div>
        <div
          style="position: absolute; top: 0; right: 0; cursor: pointer"
          @click="$store.state.isOpenSearch = false"
        >
          <img src="/images/design/fill-close.svg" alt="" />
        </div>
      </div>
    </custom-dialog>
    <b-modal
      v-model="ForgotPasswordModal"
      hide-footer
      hide-header
      no-close-on-backdrop
      centered
    >
      <b-row>
        <b-col class="d-flex justify-content-between align-items-center">
          <span> {{ $t("restore") }} </span>
          <img
            class="cursor-pointer"
            @click="ForgotPasswordModal = false"
            src="/images/design/fill-close.svg"
            alt=""
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <custom-input
            disabled
            :label="$t('phonenumber')"
            v-mask="'+998-##-###-##-##'"
            v-model="Restore.username"
            placeholder="+998 _ _  _ _ _  _ _  _ _"
          ></custom-input>
        </b-col>
      </b-row>
      <div>
        <b-row>
          <b-col>
            <p class="p-2">
              {{ $t("smssent", { phonenumber: Restore.phonenumber }) }}
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <custom-input
              type="password"
              :placeholder="$t('*******')"
              v-model="Restore.newpassword"
              :label="$t('password')"
            ></custom-input>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <custom-input
              type="password"
              :placeholder="$t('*******')"
              v-model="Restore.confirmedpassword"
              :label="$t('confirmpassword')"
            ></custom-input>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <custom-input
              v-model="Restore.smscode"
              :placeholder="$t('0000')"
              :label="$t('smskod')"
            ></custom-input>
          </b-col>
        </b-row>
      </div>
      <b-row class="mt-3">
        <b-col>
          <custom-button
            v-if="Restore.isRestore"
            @click.native="RestorePasswordConfirm"
            block
          >
            <b-spinner
              v-if="RestoreLoading"
              small
              style="margin-right: 8px"
            ></b-spinner>
            {{ $t("restore") }}
          </custom-button>
        </b-col>
      </b-row>
      <b-row> </b-row>
    </b-modal>
    <b-modal
      size="lg"
      v-model="WarningModal"
      no-close-on-backdrop
      centered
      hide-header-close
      hide-footer
      :title="$t('registrationheaderwarning')"
    >
      <b-row>
        <b-col cols="12">
          <p>
            {{ $t("registrationwarning") }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button pill variant="success" @click="SetAdmType">
            <span class="mx-2">{{ $t("ok") }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-sidebar
      @shown="GetNotifyInfo"
      v-model="notifyModal"
      no-header
      bg-variant="white"
      width="400px"
      shadow
      right
    >
      <div
        style="
          position: relative;
          width: 100%;
          height: 100%;
          padding-left: 20px;
          padding-right: 20px;
        "
      >
        <b-row class="mt-0">
          <b-col>
            <img
              @click="notifyModal = false"
              style="
                position: absolute;
                top: 0;
                right: 0 !important;
                cursor: pointer;
              "
              src="/images/design/fill-close.svg"
              alt=""
            />
          </b-col>
        </b-row>
        <b-row style="margin-top: 50px">
          <b-col>
            <h4>{{ $t("notification") }}</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div
              class="my-custom-card mt-3"
              v-for="(item, index) in NotifyList"
              :key="index"
            >
              <div class="d-flex align-items-center">
                <small style="margin-right: 5px">
                  {{ item.dateofcreated }}
                </small>
                -
                <custom-badge style="margin-left: 5px">
                  {{ item.title }}
                </custom-badge>
              </div>

              <p @click="ReadMessage(item)">
                {{ item.webtext }}
              </p>
            </div>
            <div class="my-custom-card mt-3" v-if="NotifyList.length == 0">
              <p
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  pb-0
                  mb-0
                "
              >
                {{ $t("nothinghere") }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BImg,
  BSidebar,
  BRow,
  BCol,
  BFormInput,
  BSpinner,
  BModal,
  BFormRadio,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import customInput from "../components/elements/customInput";
import customSelect from "../components/elements/customSelect.vue";
import customButtonOutline from "../components/elements/customButtonOutline.vue";
import customButton from "../components/elements/customButton.vue";
import customBadge from "../components/elements/customBadge.vue";
import admIcon from "../components/custom-icons/admIcon.vue";
import customDialog from "../components/elements/customDiaolg.vue";
import childIcon from "../components/custom-icons/childIcon.vue";
import AccountService from "../services/account.service";
import WebNotifyService from "../services/webnotify.service";
export default {
  components: {
    BImg,
    BSidebar,
    BRow,
    BCol,
    customInput,
    customButtonOutline,
    customButton,
    BFormInput,
    admIcon,
    BSpinner,
    customDialog,
    childIcon,
    customSelect,
    BModal,
    BFormRadio,
    BFormGroup,
    BButton,
    customBadge,
  },
  data() {
    return {
      isOpenDropdown: false,
      isOpenSidebar: false,
      checkAccountLoading: false,
      RestoreLoading: false,
      AddmissionTypeList: [],
      Account: {
        phonenumber: "",
        password: "",
        passwordconfirm: "",
        smscode: "",
      },
      Restore: {
        username: "",
        isRestore: false,
        phonenumber: "",
        smscode: "",
        newpassword: "",
        confirmedpassword: "",
      },
      filter: {
        isPassword: null,
        isSmsCode: false,
        isSmsForRegister: false,
        isSms: false,
        admissiontypeid: 0,
      },
      SignLoading: false,
      isToken: false,
      lang: localStorage.getItem("locale") || "uz_cyrl",
      ForgotPasswordModal: false,
      WarningModal: false,
      admtype: 0,
      notifyModal: false,
      notifycount: 0,
      NotifyList: [],
      userName: "",
      infoName: "",
    };
  },
  created() {
    this.userName = JSON.parse(localStorage.getItem("user_info"));
    window.addEventListener("scroll", this.handleScroll);
    this.Account.phonenumber = "+998";
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.popupItem = this.$el;
  },
  computed: {
    getUserName() {
      return localStorage.getItem("user_info")
        ? JSON.parse(localStorage.getItem("user_info")).UserDisplayName
        : "";
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    getUserInfo() {
      if (this.$route.name != "Home") {
        this.infoName =
          localStorage.getItem("user_info") &&
          JSON.parse(localStorage.getItem("user_info")).contractor
            ? JSON.parse(localStorage.getItem("user_info")).contractor.shortName
            : "";
      }
    },
    handleScroll(event) {
      if (document.documentElement.scrollTop < 450) {
        this.$store.state.menu_item = "main";
      }
      if (
        document.documentElement.scrollTop >= 450 &&
        document.documentElement.scrollTop < 1000
      ) {
        this.$store.state.menu_item = "news";
      }
      if (
        document.documentElement.scrollTop >
          document.getElementById("ControlFunction").offsetTop - 100 &&
        document.documentElement.scrollTop <
          document.getElementById("ControlFunction").offsetTop + 50
      ) {
        this.$store.state.menu_item = "ControlFunction";
      }
      if (
        document.documentElement.scrollTop >
          document.getElementById("MandRequirement").offsetTop - 100 &&
        document.documentElement.scrollTop <
          document.getElementById("MandRequirement").offsetTop + 50
      ) {
        this.$store.state.menu_item = "MandRequirement";
      }
      if (
        document.documentElement.scrollTop >
        document.getElementById("Videodarsliklar").offsetTop - 100
      ) {
        this.$store.state.menu_item = "Videodarsliklar";
      }
    },
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$store.state.menu_item = "main";
    },
    OpenWarningModal(type) {
      this.WarningModal = true;
      this.admtype = type;
    },
    SetAdmType() {
      if (!!this.admtype) localStorage.setItem("admtype", this.admtype);

      this.$store.state.admtype = this.admtype;
      this.WarningModal = false;
    },
    RestorePasswordConfirm() {
      this.RestoreLoading = true;
      AccountService.RestorePasswordConfirm(this.Restore)
        .then((res) => {
          this.RestoreLoading = false;
          this.ForgotPasswordModal = false;
          this.makeToast(this.$t("PasswordChangedSuccess"), "success");
        })
        .catch((error) => {
          this.RestoreLoading = false;
          this.makeToast(error.response.data.error, "error");
        });
    },
    RestorePassword() {
      this.RestoreLoading = true;
      AccountService.RestorePassword(this.Restore)
        .then((res) => {
          this.Restore.isRestore = true;
          this.Restore.phonenumber = res.data.phoneNumber;
          this.RestoreLoading = false;
        })
        .catch((error) => {
          this.makeToast(error.response.data.error, "error");
          this.RestoreLoading = false;
        });
    },
    OpenForgotPass() {
      this.ForgotPasswordModal = true;
      (this.Restore = {
        username: "",
        isRestore: false,
        phonenumber: "",
        smscode: "",
        newpassword: "",
        confirmedpassword: "",
      }),
        (this.Restore.username = this.Account.phonenumber);
      this.RestorePassword();
    },
    GoMyInfo() {
      if (this.$route.name === "info") {
        this.$store.state.isOpenSidebar = false;
      } else {
        this.$router.push({ name: "info" });
      }
    },
    GoObligations() {
      if (this.$route.name === "obligations") {
        this.$store.state.isOpenSidebar = false;
      } else {
        this.$router.push({ name: "obligations" });
      }
    },
    GoInspector() {
      if (this.$route.name === "inspector") {
        this.$store.state.isOpenSidebar = false;
      } else {
        this.$router.push({ name: "inspector" });
      }
    },
    GoControlFunction() {
      if (this.$route.name === "controlfunction") {
        this.$store.state.isOpenSidebar = false;
      } else {
        this.$router.push({ name: "controlfunction" });
      }
    },
    GoComplaint() {
      if (this.$route.name === "complaint") {
        this.$store.state.isOpenSidebar = false;
      } else {
        this.$router.push({ name: "complaint" });
      }
    },

    GoMandatoryRequirement() {
      if (this.$route.name === "mandatoryrequirement") {
        this.$store.state.isOpenSidebar = false;
      } else {
        this.$router.push({ name: "mandatoryrequirement" });
      }
    },
    GoCabinet() {
      if (this.$route.name === "Cabinet") {
        this.$store.state.isOpenSidebar = false;
      } else {
        this.$router.push({ name: "Cabinet" });
      }
    },
    Logout() {
      AccountService.Logout().then((res) => {
        localStorage.clear();
        if (this.$route.name !== "Home") {
          this.$router.push("/");
        } else {
          window.location.reload();
        }
      });
    },
    ChangePhone() {
      this.Account.password = "";
      this.Account.passwordconfirm = "";
      this.Account.smscode = "";
      this.filter.isPassword = null;
      this.filter.isSmsCode = false;
      this.filter.isSmsForRegister = false;
      this.filter.isSms = false;
    },
    ChangeLang(lang) {
      this.lang = lang;
      localStorage.setItem("locale", lang);
      window.location.reload();
    },
    OpenSidebar() {
      this.$store.state.isOpenSidebar = true;
      localStorage.removeItem("admtype");
      this.$store.state.admtype = 0;
    },
    close() {
      this.isOpenDropdown = false;
    },
    SendSmsCode() {
      this.SignLoading = true;
      if (!this.filter.isSmsForRegister) {
        AccountService.SendSMSCode(this.Account)
          .then((res) => {
            this.SignLoading = false;

            this.filter.isSmsForRegister = true;
            this.filter.isSms = true;
          })
          .catch((error) => {
            this.SignLoading = false;
            this.makeToast(error.response.data.error, "error");
          });
      } else {
        if (this.Account.smscode.length === 4) {
          AccountService.CheckSMSCode({ smscode: this.Account.smscode })
            .then((res) => {
              this.$router.push({ name: "Oferta" });
              this.SignLoading = false;
            })
            .catch((error) => {
              this.makeToast(error.response.data.error, "error");
              this.SignLoading = false;
            });
        } else {
          this.SignLoading = false;
          this.makeToast(this.$t("entersmscode"), "error");
        }
      }
    },
    GiveSmsCode() {
      this.SignLoading = true;
      AccountService.SendSMSCode(this.Account)
        .then((res) => {
          this.SignLoading = false;

          this.filter.isSmsForRegister = true;
          this.filter.isSms = true;
        })
        .catch((error) => {
          this.SignLoading = false;
          this.makeToast(error.response.data.error, "error");
        });
    },
    isCheckAccount() {
      if (this.Account.phonenumber.length === 17) {
        this.checkAccountLoading = true;
        AccountService.IsCheckAccount(this.Account)
          .then((res) => {
            this.checkAccountLoading = false;
            if (res.data.success) {
              this.filter.isPassword = true;
            }
            if (!res.data.success) {
              this.filter.isPassword = false;
            }
          })
          .catch((error) => {
            this.checkAccountLoading = false;
            this.makeToast(error.response.data.error, "error");
          });
      }
    },
    SignIn() {
      this.SignLoading = true;
      if (!this.filter.isSmsCode) {
        this.Account.username = this.Account.phonenumber;
        AccountService.SignIn(this.Account)
          .then((res) => {
            this.SignLoading = false;
            if (!res.data.trustedDevice) {
              this.filter.isSmsCode = true;
            } else {
              localStorage.setItem("user_info", JSON.stringify(res.data.user));
              this.$router.replace("/cabinet", () => {});
            }
          })
          .catch((error) => {
            this.SignLoading = false;
            this.makeToast(error.response.data, "error");
          });
      } else {
        AccountService.SignInTwoFactor(this.Account)
          .then((res) => {
            this.SignLoading = false;
            localStorage.setItem("user_info", JSON.stringify(res.data.user));
            this.$router.push({ name: "Cabinet" });
          })
          .catch((error) => {
            this.makeToast(error.response.data, "error");
            this.SignLoading = false;
          });
      }
    },
    makeToast(message, type) {
      var a = "";
      if (message.status == 500) {
        a = message.title;
      }
      if (message.status == 400) {
        var errors = Object.values(message.errors);
        var a = errors.map((el, item) => item + 1 + "." + el).join("\n");
      } else {
        a = message;
      }
      this.$toast.open({
        message: a,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
    clear() {
      this.Account = {
        phonenumber: "+998",
        password: "",
        passwordconfirm: "",
        smscode: "",
      };
      this.filter = {
        isPassword: null,
        isSmsCode: false,
        isSmsForRegister: false,
        isSms: false,
      };
    },
    GetNotifyCount() {},
    GetNotifyInfo() {
      WebNotifyService.GetUnReadMessages().then((res) => {
        this.NotifyList = res.data.result;
      });
    },
    ReadMessage(item) {
      WebNotifyService.SetMessageReadStatus(item.id).then((res) => {});
    },
  },
  watch: {
    "$store.state.isOpenSidebar": {
      handler(newValue) {
        this.clear();
      },
    },
    $route(to, from) {
      this.getUserInfo();
      this.isToken = localStorage.getItem("user_info");
      if (this.isToken) {
        this.GetNotifyCount();
      }
    },
  },
};
</script>

<style>
.usernameStyle {
  font-weight: 900 !important;
  font-size: 22px;
  margin-left: 40px;
  color: green;
}
.menu_active_color {
  color: #3f75fe !important;
}
</style>
