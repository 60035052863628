import ApiService from "./api.service";
const HealthRiskFactorService = {
  GetList(data) {
    return ApiService.post(`/HealthRiskFactor/GetList`, data);
  },

  GetSecurityInfo() {
    return ApiService.print(`/HealthRiskFactor/GetSecurityInfo`);
  },
};
export default HealthRiskFactorService;
