import ApiService from "./api.service";

const ObligationsService = {
  GetList(data) {
    return ApiService.post(`/ObligationsOfEntrepreneurCheck/GetList`, data);
  },
  GetCheck(data) {
    return ApiService.post(`/ObligationsOfEntrepreneurCheck/GetCheck`, data);
  },
  Update(data) {
    return ApiService.post(`/ObligationsOfEntrepreneurCheck/Update`, data);
  },
  Create(data) {
    return ApiService.post(`/ObligationsOfEntrepreneurCheck/Create`, data);
  },
  Get(id) {
    return ApiService.get(`/ObligationsOfEntrepreneurCheck/Get/${id}`);
  },
};
export default ObligationsService;
